<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="设备编号">
                <a-input v-model="queryParam.devCode" placeholder="请输入" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="水表编号">
                <a-input v-model="queryParam.devSn" placeholder="请输入" allow-clear/>
              </a-form-item>
            </a-col>
            <!-- <a-col :md="6" :sm="24">
              <a-form-item label="创建时间">
                <a-range-picker style="width: 100%" v-model="dateRange" valueFormat="YYYY-MM-DD" format="YYYY-MM-DD" allow-clear/>
              </a-form-item>
            </a-col> -->
            <a-col :md="!advanced && 4 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <!-- <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['global:team:add']">
          <a-icon type="plus" />新增
        </a-button>
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['global:team:edit']">
          <a-icon type="edit" />修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['global:team:remove']">
          <a-icon type="delete" />删除
        </a-button> -->
        <!-- <a-button type="primary" @click="handleExport" v-hasPermi="['global:team:export']">
            <a-icon type="download" />导出
          </a-button>
          <a-button type="dashed" :loading="refreshing" @click="handleRefreshCache" v-hasPermi="['global:team:remove']">
            <a-icon type="redo" />刷新缓存
          </a-button> -->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 详情 -->
      <recharge-form
        ref="rechargeForm"
        :typeOptions="dict.type['sys_yes_no']"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered">
        <span slot="bduserInfo" slot-scope="text, record">
          <div class="spanBox">
            <span>{{ record.userName }}</span>
            <span>{{ record.userPhone }}</span>
          </div>
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a @click="$refs.rechargeForm.info(record.id,1)" v-hasPermi="['wisdom:orderrefund:jf']">缴费</a>
          <a-divider type="vertical" v-hasPermi="['wisdom:orderrefund:tf']"/>
          <a @click="$refs.rechargeForm.info(record.id,2)" v-hasPermi="['wisdom:orderrefund:tf']">退费</a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

  <script>

  import { orderRefundJfPage } from '@/api/pages/payment-refund'
  import RechargeForm from './modules/recharge-form'
  import { tableMixin } from '@/store/table-mixin'

  export default {
    name: 'Config',
    components: {
        RechargeForm
    },
    mixins: [tableMixin],
    dicts: ['sys_yes_no'],
    data () {
      return {
        list: [],
        selectedRowKeys: [],
        selectedRows: [],
        // 高级搜索 展开/关闭
        advanced: false,
        // 非单个禁用
        single: true,
        // 非多个禁用
        multiple: true,
        ids: [],
        loading: false,
        refreshing: false,
        total: 0,
        // 日期范围
        dateRange: [],
        queryParam: {
          pageNum: 1,
          pageSize: 10
        },
        columns: [
          {
            title: '设备编号',
            dataIndex: 'devCode',
            ellipsis: true,
            align: 'center'
          },
          {
            title: '设备类型',
            dataIndex: 'typeName',
            ellipsis: true,
            align: 'center'
          },
          {
            title: '水表编号',
            dataIndex: 'devSn',
            ellipsis: true,
            align: 'center'
          },
          {
            title: '购买次数',
            dataIndex: 'buyCount',
            ellipsis: true,
            align: 'center'
          },
          {
            title: '余额',
            dataIndex: 'balance',
            ellipsis: true,
            align: 'center'
          },
          {
            title: '绑定人信息',
            dataIndex: 'bduserInfo',
            scopedSlots: { customRender: 'bduserInfo' },
            ellipsis: true,
            width: 120,
            align: 'center'
          },
          {
            title: '操作',
            dataIndex: 'operation',
            width: '15%',
            scopedSlots: { customRender: 'operation' },
            align: 'center'
          }
        ]
      }
    },
    filters: {
    },
    created () {
      this.getList()
    },
    computed: {
    },
    watch: {
    },
    methods: {
      /** 查询参数列表 */
      getList () {
        this.loading = true
        // if (this.dateRange !== null && this.dateRange !== '' && this.dateRange.length === 2) {
        //   this.queryParam.createBeginTime = this.dateRange[0] + ' 00:00:00'
        //   this.queryParam.createEndTime = this.dateRange[1] + ' 23:59:59'
        // }
        orderRefundJfPage(this.queryParam).then(response => {
            this.list = response.data.records
            this.total = response.data.total
            this.loading = false
          }
        )
      },
      /** 搜索按钮操作 */
      handleQuery () {
        this.queryParam.pageNum = 1
        this.getList()
      },
      /** 重置按钮操作 */
      resetQuery () {
        this.dateRange = []
        this.queryParam = {
          pageNum: 1,
          pageSize: 10
        }
        this.handleQuery()
      },
      onShowSizeChange (current, pageSize) {
        this.queryParam.pageSize = pageSize
        this.getList()
      },
      changeSize (current, pageSize) {
        this.queryParam.pageNum = current
        this.queryParam.pageSize = pageSize
        this.getList()
      },
      onSelectChange (selectedRowKeys, selectedRows) {
        this.selectedRowKeys = selectedRowKeys
        this.selectedRows = selectedRows
        this.ids = this.selectedRows.map(item => item.id)
        this.single = selectedRowKeys.length !== 1
        this.multiple = !selectedRowKeys.length
      },
      toggleAdvanced () {
        this.advanced = !this.advanced
      }
    }
  }
  </script>

<style scoped>
.spanBox{
  display: flex;
  flex-direction: column;
}
</style>
